<template>
    <div class="MyFollow">
      <!-- <p class="pageTop"></p> -->
      <div class="PageCommon">
        <div class="bscroll" ref="bscroll">
                <div class="bscroll-container">
        <h3 class="pageTitle" v-if="!mobileFlag">
          {{ $t("MyFollowers_BeingInvited") }}
          <div class="refreshListBtnDiv">
                <el-button class="confirmDialogBtn" @click="refreshList()">
                
                {{
                    $t("ManualReplenishment_refresh")
                }}
                <i class="el-icon-refresh-right"></i>  
              </el-button>
            </div>
        </h3>
        
        
            <div class="tableDiv" v-if="!mobileFlag">
              <el-table :data="tableData" border style="width: 100%" ref="dataTable" >
                <el-table-column  prop="portfolioName"
                  :label="$t('MyFollowers_SignalSource')">
                </el-table-column>
                <el-table-column  prop="portfolioLoginId" width="120"
                  :label="$t('MyFollowers_SignalSourceId')">
                </el-table-column>
                <el-table-column width="100" prop="shareProfitPercent" :label="$t('paymentProfitSharingRatio')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.shareProfitPercent ? scope.row.shareProfitPercent+'%' : 0}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="100" prop="durationName" :label="$t('settlementCycle')">
                </el-table-column>
                <el-table-column prop="percent" :label="$t('MyFollowers_CopyRatio')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.percent ? scope.row.percent+'%' : 0 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="maxVolume" :label="$t('MyFollowers_MaxLot')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.maxVolume == 0? $t('nolimit'):scope.row.maxVolume  }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="symbols" width="135" 
                  :label="$t('MyFollowers_Instruments1')">
                  <template slot-scope="scope">
                    <div>
                      <span class="symbolsSpan" v-if="scope.row.forexSelect">
                        {{ $t("MyFollowers_Fx") }}
                      </span>
                      <span class="symbolsSpan" v-if="scope.row.metalsSelect">
                        {{ $t("MyFollowers_Metal") }}
                      </span>
                      <span class="symbolsSpan" v-if="scope.row.energySelect">
                        {{ $t("MyFollowers_Energy") }}
                      </span>
                      <span class="symbolsSpan" v-if="scope.row.indicesSelect">
                        {{ $t("MyFollowers_Indices") }}
                      </span>
                      <span class="symbolsSpan" v-if="scope.row.cryptoSelect">
                        {{ $t("MyFollowers_Crypto") }}
                      </span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="160" :label="$t('MyFollowers_AutomaticOrderReplenishment')">
                  <template slot-scope="scope">
                    <div>
                      <el-result v-if="scope.row.autoRecon" icon="success"></el-result>
                      <el-result v-if="!scope.row.autoRecon" icon="error"></el-result>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :width="flexLabelWidth($t('MyFollowers_ActivationStatus'))"
                  :label="$t('MyFollowers_ActivationStatus')">
                  <template slot-scope="scope">
                    <div>
                      <el-result v-if="scope.row.activation" icon="success"></el-result>
                      <el-result v-if="!scope.row.activation" icon="error"></el-result>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="150" prop="createTime" :label="$t('inviteTime')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.createTime }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :fixed="tableData.length !== 0 ? 'right' : false" v-if="!mobileFlag" width="180"
                  :label="$t('MyFollowers_Operation')">
                  <template slot-scope="scope">
                    <!-- {{ scope.row.insTime | dateFilter }} -->
                    <div class="operateBtns">
                      
                      <el-button  @click="checkFollow(scope.row)" type="text" size="small"
                        v-loading.fullscreen.lock="fullscreenLoading">
                        <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                        {{ $t("MyFollowers_ViewInvitation") }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :fixed="tableData.length !== 0 ? 'right' : false" v-if="mobileFlag" width="80"
                  :label="$t('MyFollowers_Operation')">
                  <template slot-scope="scope">
                    <!-- {{ scope.row.insTime | dateFilter }} -->
                    <div class="operateBtns">
                      <el-popover placement="bottom-end" trigger="click">
                        
                        <el-button  @click="checkFollow(scope.row)" type="text" size="small"
                          v-loading.fullscreen.lock="fullscreenLoading">
                          <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                          {{ $t("MyFollowers_ViewInvitation") }}
                        </el-button>
                      </el-popover>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
              
              <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
                :next-text="$t('MyFollowers_NexPage')" @current-change="handleCurrentChange" :current-page="currentPage"
                @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]" :pager-count="5" :page-size="pageSize"
                layout="prev, pager, next, sizes" :total="total">
              </el-pagination>
            </div>
        
                    <div class="mobileTableList" v-show="mobileFlag">
                        <el-collapse accordion @change="collapseChange" v-model="activeCollapse">
                            <el-collapse-item v-for="(item,index) in tableData" :key="index" :name="index">
                            <template slot="title">
                              <div class="mobileTableCard" @click.stop="showCollapse()">
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('MyFollowers_SignalSource') }}
                                  </p>
                                  <p class="mobileTableContent" >
                                      {{ item.portfolioName }}
                                  </p>
                                  </div>
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('MyFollowers_SignalSourceId') }}
                                  </p>
                                  <p class="mobileTableContent" >
                                      {{  item.portfolioLoginId  }} 
                                  </p>
                                  </div>
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('paymentProfitSharingRatio') }}
                                  </p>
                                  <p class="mobileTableContent" >
                                      {{  item.shareProfitPercent ? item.shareProfitPercent+'%' : 0}} 
                                  </p>
                                  </div>
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('settlementCycle') }}
                                  </p>
                                  <p  class="mobileTableContent">
                                    {{ item.durationName  }}
                                  </p>
                                  </div>
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('MyFollowers_CopyRatio') }}
                                  </p>
                                  <p class="mobileTableContent" >
                                      {{  item.percent ? item.percent+'%' : 0}} 
                                  </p>
                                  </div>
                                  <div class="mobileTableItem">
                                  <p class="mobileTableTitle" >
                                      {{ $t('MyFollowers_MaxLot') }}
                                  </p>
                                  <p  class="mobileTableContent">
                                    {{ item.maxVolume == 0? $t('nolimit'):item.maxVolume  }}
                                  </p>
                                  </div>
                              </div>
                              <div class="mobileTableBtns"  @click.stop="showCollapse()">
                                <el-button class="mobileBlueBtn" v-if="item.status == 0" @click="checkFollow(item)" type="text" size="small"
                                  v-loading.fullscreen.lock="fullscreenLoading">
                                  <svg-icon class="greenIcon" icon-class="CheckIcon"></svg-icon>
                                  {{ $t("MyFollowers_ViewInvitation") }}
                                </el-button>
                              </div>
                            </template>
                            <div class="mobileTableCard" >
                                <div class="mobileTableItem">
                                <p class="mobileTableTitle" >
                                    {{ $t('MyFollowers_Instruments1') }}
                                </p>
                                <p class="mobileTableContent" >
                                  <span class="symbolsSpan" v-if="item.forexSelect">
                                    {{ $t("MyFollowers_Fx") }}
                                  </span>
                                  <span class="symbolsSpan" v-if="item.metalsSelect">
                                    {{ $t("MyFollowers_Metal") }}
                                  </span>
                                  <span class="symbolsSpan" v-if="item.energySelect">
                                    {{ $t("MyFollowers_Energy") }}
                                  </span>
                                  <span class="symbolsSpan" v-if="item.indicesSelect">
                                    {{ $t("MyFollowers_Indices") }}
                                  </span>
                                  <span class="symbolsSpan" v-if="item.cryptoSelect">
                                    {{ $t("MyFollowers_Crypto") }}
                                  </span>
                                </p>
                                </div>
                                <div class="mobileTableItem">
                                <p class="mobileTableTitle" >
                                    {{ $t('MyFollowers_AutomaticOrderReplenishment') }}
                                </p>
                                <p class="mobileTableContent" >
                                  <el-result v-if="item.autoRecon" icon="success"></el-result>
                                  <el-result v-if="!item.autoRecon" icon="error"></el-result>
                                </p>
                                </div>
                                <div class="mobileTableItem">
                                <p class="mobileTableTitle" >
                                    {{ $t('MyFollowers_ActivationStatus') }}
                                </p>
                                <p class="mobileTableContent" >
                                  <el-result v-if="item.activation" icon="success"></el-result>
                                  <el-result v-if="!item.activation" icon="error"></el-result>
                                </p>
                                </div>
                                <div class="mobileTableItem">
                                <p class="mobileTableTitle" >
                                    {{ $t('inviteTime') }}
                                </p>
                                <p  class="mobileTableContent">
                                    {{  item.createTime  }} 
                                </p>
                                </div>
                            </div>
                        </el-collapse-item>
                        
                        </el-collapse>
                        <div class="x" v-if="up && tableData.length < total">
                          <i  class="el-icon-loading "></i>
                          {{ $t('loading') }}
                        </div>
                        <div class="x" v-if="!up && tableData.length >= total">
                          
                          {{ $t('noMoreData') }}
                        </div>
                    </div>
                </div>
            </div>
        <!-- <h3 class="pageTitle">
                  {{ $t('MyFollowers_Myfollowers') }}
              </h3> -->
      </div>
      <el-drawer :title="followDrawerTitle" class="drawerCommon checkInviteDrawer" :append-to-body="true"
        @close="closeDrawer()" :close-on-press-escape="false" :visible.sync="checkInviteDrawer" direction="rtl">
        <div class="drawerContent">
          <el-form :model="followForm" :rules="followRule" ref="followForm" label-position="right" label-width="150px"
            class="drawerForm" :label-position="mobileFlag ? 'top' : 'left'">
            <!-- 跟单参数设置 -->
            <div v-show="checkInviteParameter">
              <h3 class="checkInviteDrawerTitle">
                <i></i> {{ $t("MyFollowers_ViewParametersOfBeingInvitedtoCopy") }}
              </h3>
              <el-form-item :label="$t('paymentProfitSharingRatio')">
                <el-input disabled v-model="followForm.shareProfitPercent"></el-input>
                <b class="formPercent">%</b>
              </el-form-item>
              <el-form-item :label="$t('settlementCycle')">
                <el-input disabled v-model="followForm.settlementPeriodTypeTitle"></el-input>
              </el-form-item>
              <el-form-item :label="$t('firstSettlementTime')">
                <el-input disabled v-model="followForm.currentSettlementEndTime"></el-input>
              </el-form-item>
              <el-form-item prop="following">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_CopyRatio") }} </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_CopyRatio'), $t('MyFollowers_copyPercentTxt'),$t('MyFollowers_copyPercentTxt1'))">
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
    
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>
                      <div>
                      <div>
                        {{ $t('MyFollowers_copyPercentTxt') }}
                      </div><br />
                      <div>
                        {{ $t('MyFollowers_copyPercentTxt1') }}
                      </div>
                    </div>
                    </div>
                  </span>
                </span>
                <el-input disabled v-model.number="followForm.following" autocomplete="off"></el-input>
                <b class="formPercent">%</b>
              </el-form-item>
              <el-form-item prop="maxVolume">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_MaxLot") }} </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_MaxLot'), $t('MyFollowers_maxVolumnTxt'))">
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>
                      <div>
                        <div>
                          {{ $t('MyFollowers_maxVolumnTxt') }}
                        </div><br />
                        <div>
                          {{ $t('MyFollowers_maxVolumnTxt1') }}
                        </div>
                      </div>
                    </div>
                  </span>
                </span>
                <el-input-number disabled class="creaseInput" controls-position="right" v-model.number="followForm.maxVolume" :placeholder="$t('nolimit')"
                  autocomplete="off"></el-input-number>
                  <b class="formLots">lots</b>
    
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_Instruments')">
                <el-input disabled v-model="followForm.symbols"></el-input>
              </el-form-item>
              <el-form-item prop="autoRecon">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_WhetherToAutomaticallyReplenishOrders") }}
                    </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_WhetherToAutomaticallyReplenishOrders'), $t('MyFollowers_isAutoTxt'))">
    
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_isAutoTxt") }}
                    </div>
                  </span>
                </span>
                <el-radio-group v-model="followForm.autoRecon" disabled>
                  <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
                  <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="activation" :label="$t('MyFollowers_ActivateAfterClientConsent')">
                <span slot="label">
                  <span class="label-box">
                    <span>{{ $t("MyFollowers_ActivateAfterClientConsent") }}
                    </span>
                    <a href="javascript:;"
                      @click.stop="labelIconClick($t('MyFollowers_ActivateAfterClientConsent'), $t('MyFollowers_mainActiveTxt'))">
    
                      <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
                    </a>
                    <div class="labelIconTxt">
                      <i class="el-icon-caret-top labelIconBack"></i>{{ $t("MyFollowers_mainActiveTxt") }}
                    </div>
                  </span>
                </span>
                <el-radio-group v-model="followForm.activation" disabled>
                  <el-radio :label="true">{{ $t("MyFollowers_Yes") }}</el-radio>
                  <el-radio :label="false">{{ $t("MyFollowers_No") }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <!-- 同意邀请后展示账号设置 -->
            <div v-show="checkInviteDetailAccount">
              <h3 class="checkInviteDrawerTitle">
                <i></i>{{ $t("MyFollowers_AcceptInvitation") }}
              </h3>
              <el-form-item :label="$t('brokerName')" prop="brokerName" class="toReg">
                <el-select :disabled="isFollowing" v-model="followForm.brokerName" 
                  @change="selectChange">
                  <el-option v-for="item in brokerList" :key="item.id" :label="item.brokerName" :value="item.id">
                  </el-option>
                </el-select>
                <p><span @click="toReg()">{{ $t('NoAccountRegister2')}}</span></p>
              </el-form-item>
              <el-form-item :label="$t('server')" prop="serverId">
                <!-- <el-input v-model="followForm.serverName" disabled></el-input> -->
                <el-select
                  v-model="followForm.serverId"
                  class="allLengthInput"
                  :disabled="isFollowing"
                >
                  <el-option
                    v-for="item in serverList"
                    :key="item.serverId"
                    :label="item.serverName"
                    :value="item.serverId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_ChooseMT4Account')" prop="account">
                <!-- <el-select :disabled="isFollowing" v-model="followForm.account" class="drawer_100Input"
                  @change="selectChange">
                  <el-option v-for="item in followForm.accountList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select> -->
                <el-input :disabled="isFollowing"  v-model="followForm.account" autocomplete="off" ></el-input>

              </el-form-item>
              <el-form-item v-if="checkRow.status == 0" :label="$t('MyFollowers_Mt4Password')" prop="cwPassword">
    
                <el-input type="password" v-model="followForm.cwPassword" autocomplete="off" show-password></el-input>
    
              </el-form-item>
              <el-form-item :label="$t('MyFollowers_OneClickToClose')" v-if="checkRow.status == 1">
                <div class="drawerCard">
                  <el-form-item>
                    <div class="drawerCardItem">
                      <span class="drawerCard_Label drawerCard_LabelWidth">{{
                        $t("MyFollowers_ChooseOrder")
                      }}</span>
                      <el-select class="drawerCard_100Input" v-model="followForm.orderType">
                        <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                        <el-option :label="$t('MyFollowers_order_sell')" :value="2"></el-option>
                        <el-option :label="$t('MyFollowers_order_buy')" :value="1"></el-option>
    
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="drawerCardItem">
                      <span class="drawerCard_Label drawerCard_LabelWidth">{{
                        $t("MyFollowers_PL")
                      }}</span>
                      <el-select class="drawerCard_100Input" v-model="followForm.profitType">
                        <el-option :label="$t('MyFollowers_All')" :value="0"></el-option>
                        <el-option :label="$t('MyFollowers_pl_sell')" :value="1"></el-option>
                        <el-option :label="$t('MyFollowers_pl_buy')" :value="2"></el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="drawerCardBtns">
                      <el-button class="drawerCardBtn" @click="closeOrder()">{{
                        $t("MyFollowers_ClosePosition")
                      }}</el-button>
                    </div>
                  </el-form-item>
                </div>
              </el-form-item>
            </div>

            
          </el-form>
          <div class="drawerBtns" v-if="checkInviteDetailAccount">
            <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followSubmit(1)">{{
              $t("MyFollowers_confirm") }}</el-button>
  
            <el-button class="cancelDialogBtn" @click.stop="followNext(false)">{{
              $t("MyFollowers_Back")
            }}</el-button>
          </div>
          <div class="drawerBtns" v-if="checkRow.status == 0 && !checkInviteDetailAccount">
            <el-button class="confirmDialogBtn" v-loading.fullscreen.lock="fullscreenLoading" @click="followNext(true)">{{
              $t("MyFollowers_AcceptInvitation") }}</el-button>
  
            <el-button class="cancelDialogBtn" @click.stop="isRefuse = true">{{
              $t("MyFollowers_RejectInvitation")
            }}</el-button>
          </div>
          <div class="refuseCard" v-if="isRefuse" ref="refuseCard">
            <p v-if="mobileFlag">
              {{
                $t("MyFollowers_RejectInvitation")
              }}
            </p>
            <i v-show="mobileFlag" @click="isRefuse = false" class="el-icon-close refuseIcon"></i>
            <el-input type="textarea" :rows="6" :placeholder="$t('MyFollowers_RejectInvitation_DEMO')"
              v-model="refuseReason">
            </el-input>
            <el-button class="confirmDialogBtn" @click="followSubmit(2)">{{
              $t("MyFollowers_Complete")
            }}</el-button>
          </div>
        </div>
      </el-drawer>
      <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
        <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
        <p>
          {{ labelTitle }}
        </p>
        <div >
          <div>
            {{ labelTxt }}
          </div>
          <br />
          <div v-if="labelSubContent">
            {{ labelSubContent }}
          </div>
        </div>
      </div>
      <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
        @confirmPopupTips="confirmPopup"
        @cancelPopupTips="cancelPopup"
        @cancelClosePopupTips="cancelClosePopup"
        ref="popupTipsRef"
        :isShowClose="isShowClose"
        :confirmTxt="confirmTxt"
        :cancelTxt="cancelTxt"
        :subContent="$t(subContent)"
        :content="$t(popupContent)" :btnType="btnType" />
        
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { concatParams, concatTableData, dateGmt8 } from "../../plugins/common.js";
  import { mapState } from "vuex";
  import PopupTips from '@/components/moudule/PopupTips.vue'
  import load2 from '@/components/moudule/Loading.vue'
  import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import Bus from '../../plugins/bus'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  
  BScroll.use(Pullup)
  
  export default {
    name: "MyFollow",
    components: {
      PopupTips,
      load2
    },
    computed: {
      ...mapState(["checkPortfolio", "userInfo", "token", 'mobileFlag']),
    },
    data() {
      var checkMt4 = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("MyFollowers_checkMt4Account")));
        }else if (!Number.isInteger((Number(value)))) {
          callback(new Error(this.$t("MyFollowers_checkMt4AccountNum")));
        } else {
            callback();
        }
       
      };
      return {
        tableData: [],
        currentPage: 1,
        total: 0,
        pageSize: 5,
        historyCurrentPage: 1,
        historyTotal: 0,
        historyPageSize: 5,
        checkInviteDrawer: false,
        fullscreenLoading: true,
        followForm: {
          account: "",
          following: 0,
          signalMaxDrawdown: 0,
          takeProfit: 0,
          maxDrawdown: 0,
          maxVolume: 0,
          symbols: "",
          autoRecon: true,
          activation: true,
          maxDrawdownStopFollow: false,
          takeProfitStopFollow: false,
          orderType: 0,
          profitType: 0,
          cwPassword: '',
          serverId: '',
          brokerName:1,
          currentSettlementEndTime:''
        },
        followRule: {
          account: [
            // {
            //   required: true,
            //   message: this.$t("MyFollowers_checkMt4Account"),
            //   trigger: "blur",
            // },
            { validator: checkMt4, trigger: 'change' }
          ],
          brokerName: [
            {
              required: true,
              message: this.$t("brokerNotNull"),
              trigger: "blur",
            },
          ],
          serverId: [
            {
              required: true,
              message: this.$t("serverNotNull"),
              trigger: "blur",
            },
          ],
          cwPassword: [
            { required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull'), trigger: 'blur' },
            // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
          ],
        },
        checkRow: {},
        refuseReason: "",
        isRefuse: false,
        isFollowing: false,
        followDrawerTitle: "",
        activeName: "follow",
        historyTableData: [],
        labelTitle: '',
        labelTxt: '',
        isLableTips: false,
        accountList: [],
        isPopupVisible: false,
        iconImg:require('../../assets/images/NotifyWarning.svg'),
        isStopPopupVisible:false,
        isClosePopupVisible:false,
        btnType:1,
        popupTitle:'',
        popupContent:'',
        clickType:'',
        cancelTxt:'',
        confirmTxt:'',
        isShowClose:false,
        totalProfit:0,
        totalVolume:0,
        brokerList:[],
      serverList:[],
      subContent:'',
      labelSubContent:'',
	          up:false,
            firstOpen:false,
            activeCollapse:0,
            goRegUrl:'',
            checkInviteParameter: true,
            checkInviteDetailAccount: true
      };
    },
    watch: {
    },
    mounted(){
      this.$refs.popupTipsRef.rendered = true
      // console.log(this.$refs.popupTipsRef)
    },
    created() {
      this.getData();
      this.screenClick()
      this.getServeList()
  
    },
    methods: {
      formatNumberFractionDigits,
     refreshList(){
        this.getData()
        // window.location.reload()
     },
     collapseChange(){
      this.$nextTick(() => {
            
            if(!this.firstOpen){
              this.scroll.maxScrollY = this.scroll.maxScrollY - 150
              this.firstOpen = true
            }else{
              this.scroll.maxScrollY = this.scroll.maxScrollY + 150
              this.firstOpen = false
            }
            
            
          
      });
    
      // this.tableData = []
      // this.scrollFn()
    },
     scrollFn(){
        this.$nextTick(() => {
            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }
            
            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y+50){
                    // console.log("加载更多")
                    this.up=true;
                     setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                      if(this.tableData.length < this.total){
                          this.currentPage = this.currentPage + 1
                          this.getData('noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false
                        }
                    },1000)
                   
                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })
            // console.log(this.scroll.maxScrollY)
        });
    },
     getServeList(){
      this.$axios.get("/Api/invite/BrokerList").then((res) => {
        let dt = res.data;
        if (dt.isSuccess) {
          // this.mainAccountDrawer = true
          this.brokerList = dt.result;
          this.goRegUrl = dt.result[0].registUrl
          this.serverList = dt.result[0].serverList
          // this.signalForm.portfolioName = dt.result.portfolioName
          // this.tableData = concatTableData(dt.result,10)
        }
      });
    },
     cancelPopup(val){
        this.isPopupVisible = val
        this.cancelTxt = 'MyFollowers_Cancel'
        this.confirmTxt = 'MyFollowers_confirm'
        this.subContent = ''
        this.isShowClose = false
        if(this.clickType == 'stopCopy'){
          this.cancelStopPopup()
        }
     },
      confirmPopup(val){
        this.isPopupVisible = val
        this.cancelTxt = 'MyFollowers_Cancel'
        this.confirmTxt = 'MyFollowers_confirm'
        this.subContent = ''
        this.isShowClose = false
        if(this.clickType == 'noChange'){
          this.allowSubmit()
        }else if(this.clickType == 'stopCopy'){
          this.confirmStopPopup()
        }else if(this.clickType == 'closeOrder'){
          this.confirmCloseOrder()
        }
      },
      
      getData(tag) {
        if(!this.mobileFlag || tag !== 'noRefresh'){
          this.tableData = [];

        }
        var params = {
          status: 0,
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
        };
        this.$axios.get("/Api/Invite/List" + concatParams(params)).then((res) => {
          let dt = res.data;
  
          if (dt.isSuccess) {
            // dt.result.pageList.forEach((item,index) =>{
            //   item.createTime = dateGmt8(item.createTime)
            // })
            this.total = dt.result.total;
            this.totalProfit = dt.result.totalProfit;
            this.totalVolume = dt.result.totalVolume;
            if(this.mobileFlag && tag == 'noRefresh'){
              dt.result.pageList.forEach((item) =>{
                this.tableData.push(item)
              })
            
            
          }else{
            this.tableData = concatTableData(
              dt.result.pageList,
              dt.result.total,
              this.currentPage,
              this.pageSize
            );
          }
          
          this.fullscreenLoading = false;
          if(this.mobileFlag){
            this.scrollFn()
          }

          console.log(this.tableData)
            // this.$refs.dataTable.doLayout();
          }
        });
      },
      // 查看邀请
      checkFollow(row) {
        this.isFollowing = false;
        this.isRefuse = false;
        this.checkRow = row;
        this.checkInviteParameter = true
        this.checkInviteDetailAccount = this.checkRow.status == 0 ? false : true 
        this.followDrawerTitle = this.$t("MyFollowers_ViewInvitation");
        // this.checkInviteDrawer = true
        var params = {
          id: row.id,
        };
        this.$axios
          .get("/Api/Invite/GetInvite" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.checkInviteDrawer = true;
              this.$nextTick(function () {
                this.followForm = Object.assign(this.followForm, dt.result);
                this.followForm.forexSelectLabel = this.followForm.forexSelect
                  ? this.$t("MyFollowers_Fx")
                  : "";
                this.followForm.energySelectLabel = this.followForm.energySelect
                  ? this.$t("MyFollowers_Energy")
                  : "";
                this.followForm.metalsSelectLabel = this.followForm.metalsSelect
                  ? this.$t("MyFollowers_Metal")
                  : "";
                this.followForm.indicesSelectLabel = this.followForm.indicesSelect
                  ? this.$t("MyFollowers_Indices")
                  : "";
                this.followForm.cryptoSelectLabel = this.followForm.cryptoSelect
                  ? this.$t("MyFollowers_Crypto")
                  : "";
                this.followForm.symbols =
                  this.followForm.forexSelectLabel +
                  " " +
                  this.followForm.energySelectLabel +
                  " " +
                  this.followForm.metalsSelectLabel +
                  " " +
                  this.followForm.indicesSelectLabel +
                  " " +
                  this.followForm.cryptoSelectLabel;
                  this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
                  
              });
              // console.log(this.followForm)
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
        // this.$alert(this.$t('checkFollowBefore'), this.$t("MyFollowers_prompt"),{
        //   confirmButtonText: this.$t('MyFollowers_confirm'),
        //   customClass:'alertClass',
        //   callback: action => {
  
        //   }
        // });
  
      },
      // 设置
      getSetting(row) {
        this.isFollowing = true;
        this.checkRow = row;
        this.isRefuse = false;
        this.followDrawerTitle = this.$t("MyFollowers_Setting");
        var params = {
          id: row.id,
        };
        // console.log(this.checkRow)
        this.$axios
          .get("/Api/Invite/GetSetting" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.checkInviteDrawer = true;
              this.$nextTick(function () {
                this.followForm = Object.assign(this.followForm, dt.result);
                this.followForm.forexSelectLabel = this.followForm.forexSelect
                  ? this.$t("MyFollowers_Fx")
                  : "";
                this.followForm.energySelectLabel = this.followForm.energySelect
                  ? this.$t("MyFollowers_Energy")
                  : "";
                this.followForm.metalsSelectLabel = this.followForm.metalsSelect
                  ? this.$t("MyFollowers_Metal")
                  : "";
                this.followForm.indicesSelectLabel = this.followForm.indicesSelect
                  ? this.$t("MyFollowers_Indices")
                  : "";
                this.followForm.cryptoSelectLabel = this.followForm.cryptoSelect
                  ? this.$t("MyFollowers_Crypto")
                  : "";
                this.followForm.symbols =
                  this.followForm.forexSelectLabel +
                  " " +
                  this.followForm.energySelectLabel +
                  " " +
                  this.followForm.metalsSelectLabel +
                  " " +
                  this.followForm.indicesSelectLabel +
                  " " +
                  this.followForm.cryptoSelectLabel;
                  this.followForm.maxVolume = this.followForm.maxVolume == 0?undefined:this.followForm.maxVolume
                  this.followForm.orderType = 0
                  this.followForm.profitType = 0
                  this.brokerList.forEach(item=>{
                    if(item.brokerName == dt.result.brokerName ){
                      this.followForm.brokerName = item.id
                    }
                    item.serverList.forEach(subItem =>{
                      if(item.serverName == dt.result.serverName ){
                        this.followForm.serverId = subItem.id
                      }
                    })
                  })
              });
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      },
      closeDrawer() {
        this.$refs.followForm.resetFields();
        this.checkInviteDrawer = false;
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getData(this.currentPage, this.pageSize);
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getData(this.currentPage, this.pageSize);
      },
      followNext(status){
        this.checkInviteParameter = status? false : true
        this.checkInviteDetailAccount =  status?  true :false
      },
      followSubmit(status) {
        if (status == 1) {
          this.$refs.followForm.validate((valid) => {
            if (valid) {
              // console.log(111111111111)
              this.btnType = 1
              this.clickType = 'noChange'
              this.popupTitle = 'noChangePassword'
              this.popupContent  = 'noChangePasswordContent'
              this.confirmTxt = 'MyFollowers_confirm'
              this.isShowClose = true
              this.isPopupVisible = true
            }
          });
        } else {
          this.fullscreenLoading = true;
          var params = {
            id: this.checkRow.id,
            // maxDrawdown: this.followForm.maxDrawdown,
            // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
            // takeProfit: this.followForm.takeProfit,
            // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
            status: status,
            refuseReason: this.refuseReason,
          };
          this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize)
              this.$refs.followForm.resetFields();
              this.refuseReason = ""
              this.checkInviteDrawer = false;
              Bus.$emit('isInviteRefresh', true);

              //   this.getStatusSignalIR();
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          }).finally(() => {
            this.fullscreenLoading = false;
          });
  
        }
      },
      //同意邀请
      allowSubmit(){
        this.fullscreenLoading = true;
              var params = {
                id: this.checkRow.id,
                account: this.followForm.account,
                cwPassword: this.followForm.cwPassword,
                // maxDrawdown: this.followForm.maxDrawdown
                //   ? this.followForm.maxDrawdown
                //   : 0,
                // maxDrawdownStopFollow: this.followForm.maxDrawdownStopFollow,
                // takeProfit: this.followForm.takeProfit
                //   ? this.followForm.takeProfit
                //   : 0,
                // takeProfitStopFollow: this.followForm.takeProfitStopFollow,
                serverId:this.followForm.serverId,
                status: 1,
              };
              this.$axios.post("/Api/Invite/PostInvite", params).then((res) => {
                let dt = res.data;
                if (dt.isSuccess) {
  
                  this.getData()
                  this.$notify.success({title: this.$t('nofity_success'), message:dt.result});
                  this.$refs.followForm.resetFields();
                  this.checkInviteDrawer = false;
                  Bus.$emit('isInviteRefresh', true);

                } else {
                  this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                }
              }).finally(() => {
                this.fullscreenLoading = false;
              });
      },
      // 设置保存  暂时取消
      followSettingSave(type) {
        // console.log(this.followForm)
        this.fullscreenLoading = true;
        var setValue = 0;
        if (type == 1) {
          if (this.followForm.maxDrawdown) {
            setValue = this.followForm.maxDrawdown;
          } else {
            setValue = 0;
          }
        } else {
          if (this.followForm.takeProfit) {
            setValue = this.followForm.takeProfit;
          } else {
            setValue = 0;
          }
        }
        var params = {
          id: this.checkRow.id,
          setValue: setValue,
          type: type,
        };
        // console.log(params)
        this.$axios
          .post("/Api/Invite/PostSetting", params)
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});
              this.getData(this.currentPage, this.pageSize);
              this.checkInviteDrawer = false;
              this.$refs.followForm.resetFields();
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      },
      //// 一键平仓确认走接口
      confirmCloseOrder(){
        this.fullscreenLoading = true;
        var params = {
          account: this.checkRow.account,
          orderType: Number(this.followForm.orderType),
          profitType: Number(this.followForm.profitType),
        };
        this.$axios
          .get("/Api/Invite/CloseOrder" + concatParams(params))
          .then((res) => {
            let dt = res.data;
            if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

              this.getData(this.currentPage, this.pageSize);
              this.checkInviteDrawer = false;
              this.$refs.followForm.resetFields();
            } else {
              this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
            }
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      },
      // 一键平仓
      closeOrder() {
        this.btnType = 2
        this.clickType = 'closeOrder'
        this.popupTitle = 'closeOrderTitle'
        this.popupContent = 'closeOrderContent'
        this.subContent = 'MyFollowers_stopCopingContent1'
        this.isPopupVisible = true
        
      },
      stopFollow(row) {
        // this.$confirm(
        //   this.$t("MyFollowers_stopCopingConfirm"),
        //   this.$t("MyFollowers_prompt"),
        //   {
        //     confirmButtonText: this.$t("MyFollowers_confirm"),
        //     cancelButtonText: this.$t("MyFollowers_Cancel"),
        //     type: "warning",
        //   }
        // ).then(() => {
          this.btnType = 2
          this.clickType = 'stopCopy'
          this.popupTitle = 'MyFollowers_StopCopying'
          this.popupContent = 'MyFollowers_stopCopingContent'
          this.cancelTxt = 'stop_cancelTxt'
          this.confirmTxt = 'stop_confirmTxt'
          this.isShowClose = true
          this.isPopupVisible = true
          this.checkRow = row
         
        // });
      },
      // 停止跟随 选择是
      confirmStopPopup(val){
        var params = {
            id: this.checkRow.id,
            closeOrder:1
          };
          this.$axios
            .get("/Api/Invite/StopFollow" + concatParams(params))
            .then((res) => {
              let dt = res.data;
  
              if (dt.isSuccess) {
              this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

                this.getData(this.currentPage, this.pageSize);
              } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
              }
            });
      },
      // 停止跟随 选择否
      cancelStopPopup(){
        var params = {
            id: this.checkRow.id,
            closeOrder:0
          };
          this.$axios
            .get("/Api/Invite/StopFollow" + concatParams(params))
            .then((res) => {
              let dt = res.data;
  
              if (dt.isSuccess) {
                this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

                this.getData(this.currentPage, this.pageSize);
              } else {
                this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
              }
            });
      },
      // 停止跟随关闭
      cancelClosePopup(val){
        this.isPopupVisible = val
        this.cancelTxt = 'MyFollowers_Cancel'
        this.confirmTxt = 'MyFollowers_confirm'
        this.isShowClose = false
      },
      // mt4选择
      selectChange(val) {
        // console.log(val)
        this.brokerList.forEach((item,index) =>{
          if(item.id == val){
            this.serverList = item.serverList
            this.goRegUrl = item.registUrl
          }
        })
        // this.$axios
        //   .post("/Api/Invite/EquityLessthanMaxDrawdown", {
        //     id: this.checkRow.id,
        //     account: val,
        //   })
        //   .then((res) => {
        //     let dt = res.data;
  
        //     if (dt.isSuccess) {
        //       //               this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess')});

        //       // this.getData(this.currentPage, this.pageSize)
        //     } else {
        //       this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
        //     }
        //   });
      },
      labelIconClick(title, content,subContent) {
  
        if (this.mobileFlag) {
          this.isLableTips = !this.isLableTips
          this.labelTitle = title
          this.labelTxt = content
          this.labelSubContent = subContent

        } else {
          this.isLableTips = false
        }
      },
      screenClick() {
        // debugger
        document.addEventListener("click", e => {
          if (this.$refs.mobileLabelTips) {
            let self = this.$refs.mobileLabelTips.contains(e.target);
            if (!self) {
              this.isLableTips = false;
            this.labelSubContent = ''

            }
          }
          if (this.$refs.refuseCard) {
            let self = this.$refs.refuseCard.contains(e.target);
            if (!self) {
              this.isRefuse = false;
            }
          }
        })
  
      },
      toEcharts(account){
          this.$router.push({ path: '/FollowEcharts',query:{account:account} });
      },
      getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = this.$t('echarts_total');
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (column.property === 'profit' || column.property === 'volume' || column.property === 'equity' || column.property === 'balance') {
                      sums[index] = values.reduce((prev, curr) => {
                          const value = Number(curr)
                          if (!isNaN(value)) {
                              return prev + curr
                          } else {
                              return prev
                          }
                      }, 0)
                      
                      sums[index] = formatNumberFractionDigits(sums[index]) 
                  } else {
                      sums[index] = ' '
                  }
            });
  
            return sums;
  
        },
        toReg(){
          window.open('https://crm.ecmarkets.co/zh-CN/sign/register')
        }
    },
  };
  </script>
  <style lang="less" scoped>
  .MyFollow {}
  .formLots{
    right: 12%;
  }
  .pageTitle{
    justify-content: flex-start;
    align-items: center;
  }
.refreshListBtnDiv {
  margin-left: 10px;
  .confirmDialogBtn {
    color: #FFF;
    text-align: center;
    font-family: 'PingFangSC-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding:8px 10px;
    
  }
}
  @media screen and (max-width:767px){
    .tableDiv{
      padding-top: 40px!important;
    }
  }
  </style>
  